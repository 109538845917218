/*
 * @Date: 2021-12-20 11:25:20
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\main.js
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import './element-variables.scss';
import Axios from 'axios';
import App from './App.vue'
import store from './store'
import router from './router'
Vue.prototype.$axios = Axios;
Vue.config.productionTip = false
Vue.use(ElementUI);

import api from './api'
Vue.prototype.$api = api;
router.beforeEach((to, form, next)=>{
    if(to.meta.title){
        document.title = to.meta.title;
    }
    next();
})
window.callParentPageMethod = function(url){
	location.href = url;
}
window.vm = new Vue({
 store,
 router,
 render: h => h(App)
}).$mount('#app')

