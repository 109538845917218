<!--
 * @Date: 2021-12-21 11:16:36
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\components\listItem.vue
-->
<template>
    <div>
        <div class="listItem" v-if="datakey">
            <div class="listItemwk" @click="getDetail">
                <div class="listItemImg">
                    <el-image
                        style="width: 109px; height: 160px"
                        :src="datakey.cover_image"
                        fit="fill"
                    ></el-image>
                </div>
                <div class="listCN" :style="{maxHeight:showDown?'200px':'auto',minHeight:showDown?'200px':'160px'}">
                    <el-descriptions
                        size="small"
                        class="margin-top"
                        :title="datakey.name"
                        :column="1"
                    >
                        <el-descriptions-item label="类别" label-class-name="my-label">
                            <span
                                v-for="(item,index) in datakey.categorymagazine"
                                :key="index"
                                style="width:100%"
                            >
                                <el-tag
                                    v-for="(item2,index2) in item.categorys"
                                    :key="index2"
                                    size="mini"
                                >{{item2.name}}</el-tag>
                            </span>
                            <el-tag size="mini">{{datakey.level_id}}/{{datakey.period_id}}</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item
                            label="批次"
                            label-class-name="my-label"
                        >{{datakey.batch_id||"-"}}</el-descriptions-item>
                        <el-descriptions-item
                            label="出刊时间"
                            label-class-name="my-label"
                        >{{datakey.published_Year_Month}} {{datakey.published_period_id_str}}</el-descriptions-item>
                        <el-descriptions-item label="上网情况" label-class-name="my-label">
                            <span v-for="(item,index) in datakey.magazinenetwork" :key="index">
                                <el-tag
                                    v-for="(item2,index2) in item.network"
                                    :key="index2"
                                    size="mini"
                                >{{item2.name}}</el-tag>
                            </span>
                        </el-descriptions-item>
                        <el-descriptions-item label="当前期刊" label-class-name="my-label" v-if="showDown">
                            <div class="textOverflow">{{datakey.current_publish_date}}</div>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div v-if="datakey.examination_standard" class="listJi" >
                    <el-avatar>{{datakey.examination_standard}}</el-avatar>
                </div>
            </div>
            <div class="downloadCtrolBar" v-if="showDown">
                <a :href="datakey.cover_image" target="_blank" style="padding-right:12px">
                    <el-button type="primary" plain icon="el-icon-picture-outline">下载封面图</el-button>
                </a>

                <a :href="datakey.letter" target="_blank">
                    <el-button type="primary" plain icon="el-icon-picture-outline">下载征稿函</el-button>
                </a>
            </div>
        </div>
        <el-dialog
            :visible.sync="showChangPassword"
            width="85%"
            title="期刊详细资料"
            custom-class="diglobody"
            
            :close-on-click-modal="false"
        >
            <div style="max-height:90vh;min-height:40vh" v-loading="loaddingDetail">
                <el-row :gutter="24" v-if="hdetail">
                    <el-col :span="5">
                        <el-image
                            v-show="hdetail.cover_image"
                            style="width: 100%; height: auto;"
                            :src="hdetail.cover_image"
                            fit="fill"
                        ></el-image>

                        <div style="height:24px"></div>
                        <el-descriptions size="small" class="margin-top" :column="1">
                            <div slot="title">
                                {{hdetail.name}}
                                <span
                                    style="color:#01a1c4"
                                >{{hdetail.examination_standard}}</span>
                            </div>
                            <el-descriptions-item
                                label="类别"
                                label-class-name="my-label"
                                contentClassName="my-tag"
                            >
                                <span
                                v-for="(item,index) in hdetail.categorymagazine"
                                :key="index"
                                style="width:100%"
                                >
                                <el-tag
                                    v-for="(item2,index2) in item.categorys"
                                    :key="index2"
                                    size="mini"
                                >{{item2.name}}</el-tag>
                                </span>
                                <el-tag size="mini">{{hdetail.level_id}}/{{hdetail.period_id}}</el-tag>

                            </el-descriptions-item>
                            <el-descriptions-item
                                label="批次"
                                label-class-name="my-label"
                            >{{hdetail.batch_id||"-"}}</el-descriptions-item>
                            <el-descriptions-item
                                label="出刊时间"
                                label-class-name="my-label"
                            >{{hdetail.published_Year_Month}} {{hdetail.published_period_id_str}}</el-descriptions-item>
                            <el-descriptions-item
                                label="上网情况"
                                label-class-name="my-label"
                                contentClassName="my-tag"
                            >
                                <span
                                    v-for="(item,index) in hdetail.magazinenetwork"
                                    :key="index"
                                    style="width:100%"
                                >
                                    <el-tag
                                        v-for="(item2,index2) in item.network"
                                        :key="index2"
                                        size="mini"
                                    >{{item2.name}}</el-tag>
                                </span>
                            </el-descriptions-item>
                            <el-descriptions-item
                                label="当前刊期"
                                label-class-name="my-label"
                            >{{hdetail.current_publish_date}}</el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    <el-col :span="9">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span style="font-weight:bold;font-size:21px">期次信息</span>
                            </div>
                            <div style="max-height:65vh;overflow-y:auto;padding-right:10px">
                                <div v-for="(item,index) in hdetail.period" :key="index">
                                    <h4
                                        v-if="item.indexOf('年记录')>-1"
                                        style="color:#01a1c4;padding:12px 0;font-weight:bold;font-size:18px"
                                    >{{item}}</h4>
                                    <h5
                                        v-if="item.indexOf('月记录')>-1"
                                        style="color:#01a1c4;padding:12px 0;font-weight:bold;font-size:14px"
                                    >{{item}}</h5>
                                    <div
                                        v-if="item.indexOf('月记录')==-1&&item.indexOf('年记录')==-1"
                                        style="font-size:14px;padding-bottom:6px;color:#888"
                                    >{{item}}</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="10">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span style="font-weight:bold;font-size:21px">基本信息</span>
                            </div>
                            <div style="max-height:65vh;overflow-y:auto">
                                <!-- 大客户审测价格:成本价、新审测价格:代理价、审测价格:市场价 -->
                                <!-- <div style="height:24px"></div> -->
                                <div>
                                    <!-- 大客户审测价格:成本价 -->
                                    <div class="desc-label">
                                        <span style="font-weight:bold">成本价：</span>
                                        <span>{{hdetail.agent_price_memo}}</span>
                                    </div>
                                    <!-- 新审测价格:代理价 -->
                                    <div class="desc-label">
                                        <span style="font-weight:bold">代理价：</span>
                                        <span>{{hdetail.new_agent_price_memo}}</span>
                                    </div>
                                    <!-- 审测价格:市场价  -->
                                    <div class="desc-label">
                                        <span style="font-weight:bold">市场价：</span>
                                        <span>{{hdetail.retail_price_memo}}</span>
                                    </div>
                                    <!-- 国内刊号 -->
                                    <div class="desc-label">
                                        <span style="font-weight:bold">国内刊号：</span>
                                        <span>{{hdetail.CN}}</span>
                                    </div>
                                    <!-- 国际刊号 -->
                                    <div class="desc-label">
                                        <span style="font-weight:bold">国际刊号：</span>
                                        <span>{{hdetail.ISSN}}</span>
                                    </div>
									<div class="desc-label">
									    <span style="font-weight:bold">邮发代号：</span>
									    <span>{{hdetail.youhaodaifa}}</span>
									</div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">审测标准：</span>
                                        <span>{{hdetail.examination_standard}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">版面字符：</span>
                                        <span>{{hdetail.chars}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">主办单位：</span>
                                        <span>{{hdetail.host_unit}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">主管单位：</span>
                                        <span>{{hdetail.manage_unit}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <!-- cover_core -->
                                        <span style="font-weight:bold">封面是否带有核心字样：</span>
                                        <span>{{hdetail.cover_core==0?'无':'有'}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">影响因子：</span>
                                        <span>{{hdetail.factor}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">样刊：</span>
                                        <span>{{hdetail.sample}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">页数：</span>
                                        <span>{{hdetail.pages}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">查重率：</span>
                                        <span>{{hdetail.check_rate}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">省市邮编：</span>
                                        <span>{{hdetail.province_city_postcode}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">摘要关键字：</span>
                                        <span>{{hdetail.summary}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">作者简介：</span>
                                        <span>{{hdetail.author}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">参考文献：</span>
                                        <span>{{hdetail.document}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">审稿难度：</span>
                                        <span>{{hdetail.reviewing}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">审稿时长：</span>
                                        <span>{{hdetail.check_time}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">栏目：</span>
                                        <span>{{hdetail.column}}</span>
                                    </div>
                                    <div class="desc-label">
                                        <span style="font-weight:bold">附件信息：</span>
                                        <span>{{hdetail.extra||"-"}}</span>
                                        <!-- letter为 征函稿下载字段 -->
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>

// var listDatajs = require('./list.json');
// var detailJson = require('./detajson.json');
export default {
    name: "listItem",
    props: {
        hdata: {
            type: Object,
            default: () => null
        },
        showDown: {
            type: Boolean,
            default: true,
        }
    },
    created() {
        this.datakey = this.hdata;
    },
    data() {
        return {
            showChangPassword: false,
            //列表字段集
            datakey: null,
            //详细资料字段集
            hdetail: null,
            loaddingDetail:false,
        }
    },
    methods: {
        getDetail() {
            if(!this.$store.state.token){
                this.$message({ message: '你还未登录', type: 'error' })
                this.$store.commit("setloginAlert", true);
                return;
            }
            let t = this;
            if (!this.datakey) return;
            t.loaddingDetail=true;
            this.$api.getPeriodicaDetail(this.datakey.id).then(d => {
               
                this.showChangPassword = true;
                setTimeout(() => {
                   t.hdetail = d;
                   t.loaddingDetail=false; 
                }, 600);
            })
        }
    }
}
</script>
<style>
.textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.my-label {
    flex-shrink: 0;
}
.my-tag .el-tag {
    margin-right: 5px;
    margin-bottom: 3px;
}
.diglobody {
    margin-top: 5vh !important;
}
</style>
<style scoped lang="scss">
.downloadCtrolBar {
    width: 100%;
    flex-shrink: 0;
    padding-top: 12px;
}
.desc-label {
    margin-bottom: 12px;
}
.listItem {
    background: white;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 12px;
    &:hover {
        box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.1);
    }
}
.listItemwk {
    display: flex;
    cursor: pointer;
    .listItemImg {
        width: 109px;
        height: 160px;
        flex-shrink: 0;
        border-radius: 5px;
        overflow: hidden;
    }
    .listCN {
        margin-left: 12px;
        .el-tag {
            margin-right: 5px;
            margin-bottom: 3px;
        }
        min-height: 200px;
        max-height: 200px;
    }
    .listJi {
        flex-shrink: 0;
        &,
        * {
            text-transform: uppercase;
        }
        .el-avatar {
            background: #01a1c4;
            color: white;
        }
    }
}
</style>