/*
 * @Date: 2021-12-22 13:54:19
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\api.js
 */
import Axios from 'axios';
function req(url,method='post', data = {}, params={}, headers={}) {
    var token = localStorage.getItem("token")||"";
    // var token = "37SM23NyjWPo5Mxd4uhzqDKSLeep4mDLC9x3roLm";
    // https://mockapi.eolinker.com/DDwI17ybe218aa74f85a6621f9ce82123d4350b16c37bde/search
    return new Promise((res, rej) => {
        Axios({
            method: method,
            url: '/wxappapi'+url,
            data: data,
            params: params,
            headers: { 
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": 'Bearer '+token,
                ...headers
            }
            
            
        }).then(response=>{
            if(response.status !=200){
                window.vm.$message({ message: "错误码："+response.status, type: 'error' });
                return;
            }
            
            let isJSON = true;
            if (typeof response.data !== 'object') {
                isJSON = false;
            }
            if (!isJSON) {
                window.vm.$message({ message: "非正常数据格式:"+response.data, type: 'error' });
                return;
            }
            let data = response.data;
            if (data.code !== 0) {
               if(data.code==-1){
                   window.vm.$store.commit('loginOut')
               }
                window.vm.$message({ message: data.msg + response.status, type: 'error' });
                rej();
                return;
            }

            res(data.data);
        }).catch(error=>{
            window.vm.$message({ message: "错误码：" + error, type: 'error' });
            rej()
        })



    })
}

const api = {
    //首页获取期刊列表。
    getPeriodicalList(page=1,formData){
        return req("/magazine/search", "post", { page: page,...formData}, {})
    },
    //获取期刊详情
    getPeriodicaDetail(id=null) {
        return req("/magazine/detail", "post", { id: id}, {})
    },
    //获取首页筛选条件。
    getsearchkey(){
        return req("/magazine/getsearchkey")
    },
     //首页获取出刊列表文字列表
    getperStrList(page = 1) {
        return req("/magazine/getperStrList", "post", { page: page }, {})
    },
    ///获取出刊统计栏目的列表，
    gettongjileimu() {
        return req("/magazine/gettongjileimu", "post", {}, {})
    },
    ///获取出刊统计列表数据
    gettongjiList(date) {
        return req("/magazine/gettongjiList", "post", { published_date: date }, {})
    },
    //登录帐户
    loginaccont(phone, password, key,code) {
        return req("/user/loginFromWeb", "post", { phone: phone, password: password,key:key,code:code }, {})
    },
    //修改密码
    changepassword(phone, password,newpassword) {
        return req("/user/changepassword", "post", { phone: phone, password: password, newpassword: newpassword }, {})
    },
    //获取登录的用户信息 
    getuserinfo() {
        return req("/user/userinfo", "post", {}, {})
    },
    //获取系统列表信息
    getsystemlist() {
        return req("/magazine/getsystemlist", "post", {}, {})
    },
    //上传图片
    uploadimg() {
        return req("/sys/uploadImg", "post", {}, {})
    },
    //提交反馈信息
    putFeedBack(content,image) {
        return req("/user/feedback", "post", { content: content,image:image}, {})
    },
    //退出登录
    logout() {
        return req("/user/logout", "post", {}, {})
    },
   
    getCodeImg(key){
       return req("/sys/captcha?key="+key, "get", {}, {})
    },
	getWebToken(){
	   return req("/getWebToken", "get", {}, {})
	}
}

export default api
