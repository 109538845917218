<!--
 * @Date: 2021-12-20 11:42:45
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\views\Home.vue
-->
<template>
    <div class="home" >
        <el-backtop :right="100" :bottom="100"></el-backtop>
        <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
        <div class="homebg">
            <div class="homebgBody">
                <div class="homebgBody-title">期刊查询系统</div>
                <div class="homebgBody-input">
                    <div style="width:calc(100% - 170px)">
                        <el-input placeholder="一键搜索刊：例如《空中英语》" v-model="form.keywords" clearable></el-input>
                    </div>
                    <el-button-group style="flex-shrink: 0;margin-left:30px;width:140px">
                        <el-button @click="search" type="primary" plain>搜索</el-button>
                        <el-button @click="reset" type>重置</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="searchForm" v-if="search_key" >
            <div style="height:32px" ></div>
            <div class="searchFormBody"  :style="{height:loadMoreSearch?'auto':'300px',overflow:'hidden'}">
                            <el-form
           
            v-if="search_key"
                ref="form"
                :model="form"
                label-position="right"
                size="small"
                label-width="150px"
               
            >
                <el-form-item label="文章方向 ：" style="text-align: left;" prop="categories">
                    <el-checkbox-group v-model="form.categories">
                        <el-checkbox
                            v-for="(item,index) in search_key.categories"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="数据收录 ：" style="text-align: left;" prop="network">
                    <el-checkbox-group v-model="form.network">
                        <el-checkbox
                            v-for="(item,index) in search_key.network"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="期刊级别 ：" style="text-align: left;" prop="level">
                    <el-checkbox-group v-model="form.level">
                        <el-checkbox
                            v-for="(item,index) in search_key.level"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="出刊周期 ：" style="text-align: left;" prop="period">
                    <el-checkbox-group v-model="form.period">
                        <el-checkbox
                            v-for="(item,index) in search_key.period"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="当下收录年份 ：" style="text-align: left;"  prop="checkedIncluded">
                    <el-checkbox-group v-model="form.checkedIncluded">
                        <el-checkbox
                            v-for="(item,index) in search_key.checkedIncluded"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="页码 ：" style="text-align: left;" prop="pageses">
                    <el-checkbox-group v-model="form.pageses">
                        <el-checkbox
                            v-for="(item,index) in search_key.pageses"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                
                <el-form-item label="审测标准 ：" style="text-align: left;" prop="examination_standard">
                    <el-checkbox-group v-model="form.examination_standard">
                        <el-checkbox
                            v-for="(item,index) in search_key.examination_standard"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="开社内发票 ：" style="text-align: left;" prop="invoice_type">
                    <el-checkbox-group v-model="form.invoice_type">
                        <el-checkbox
                            v-for="(item,index) in search_key.invoice_type"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="暂停收稿刊物 ：" style="text-align: left;" prop="pause">
                    <el-checkbox-group v-model="form.pause">
                        <el-checkbox
                            v-for="(item,index) in search_key.pause"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="第一，二批 ：" style="text-align: left;" prop="batch_id">
                    <el-checkbox-group v-model="form.batch_id">
                        <el-checkbox
                            v-for="(item,index) in search_key.batch_id"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="邮发代号 ：" style="text-align: left;" prop="postcode">
                    <el-checkbox-group v-model="form.postcode">
                        <el-checkbox
                            v-for="(item,index) in search_key.postcode"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="电子刊号 ：" style="text-align: left;" prop="electronic_num">
                    <el-checkbox-group v-model="form.electronic_num">
                        <el-checkbox
                            v-for="(item,index) in search_key.electronic_num"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="是否可查稿 ：" style="text-align: left;" prop="check_draft_phone">
                    <el-checkbox-group v-model="form.check_draft_phone">
                        <el-checkbox
                            v-for="(item,index) in search_key.check_draft_phone"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="封面核心 ：" style="text-align: left;" prop="cover_core">
                    <el-checkbox-group v-model="form.cover_core">
                        <el-checkbox
                            v-for="(item,index) in search_key.cover_core"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="影响因子 ：" style="text-align: left;" prop="factor">
                    <el-checkbox-group v-model="form.factor">
                        <el-checkbox
                            v-for="(item,index) in search_key.factor"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="影响因子区间 ：" style="text-align: left;" prop="influence_id">
                    <el-checkbox-group v-model="form.influence_id">
                        <el-checkbox
                            v-for="(item,index) in search_key.influence_id"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="出刊限制时间 ：" style="text-align: left;" prop="published_date">
                    <el-row>
                        <el-col :span="6">
                            <el-date-picker
                                @change="timechange"
                                v-model="form.published_date"
                                type="month"
                                placeholder="选择月"
                            ></el-date-picker>
                        </el-col>
                        <el-col :span="18" v-if="form.published_date">
                             <el-form-item prop="published">
                                <el-radio-group v-model="form.published">
                                <el-radio
                                    v-for="(item,index) in search_key.published"
                                    :key="index"
                                    :label="item.text"
                                    name="type"
                                ></el-radio>
                            </el-radio-group>
                             </el-form-item>
                            
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="价格排序 ：" style="text-align: left;" prop="agentPriceOrder">
                    <el-radio-group v-model="form.agentPriceOrder">
                        <el-radio
                            v-for="(item,index) in search_key.agentPriceOrder"
                            :key="index"
                            :label="item.text"
                            name="type"
                        ></el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            
            </div>

            <div @click="loadMoreSearch=!loadMoreSearch" class="loadmore" >
                <span>展开更多筛选条件</span>
                <i v-if="!loadMoreSearch" class="el-icon-caret-bottom"></i>
                <i v-if="loadMoreSearch" class="el-icon-caret-top"></i>
        </div>
        </div>
        
        <div class="searchrulst" >
            <div style="height:32px"> </div>
             <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane  label="期刊列表" name="first" v-loading="per_loading">
                    <div  class="qikanList" v-if="per_list.length==0" style="display:flex;justify-content:center;align-items:center">
                        <el-empty description="数据为空或者你还未登录哦~"></el-empty>
                    </div>
                    <div class="qikanList"  v-if="$store.state.token">
                         <div class="bdy-right-item" v-for="(item,index) in per_list" :key="index" >
                            <list-item :hdata="item"></list-item >
                        </div>
                    </div>
                    <div v-if="per_isMore&&$store.state.token"  class="qikanList" style="display:flex;justify-content:center;align-items:center">
                        <el-button @click="getPeriodicalList" type="primary">继续加载更多</el-button>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="当前期刊" name="second" v-loading="str_loading">
                    <div  class="qikanList" v-if="str_list.length==0" style="display:flex;justify-content:center;align-items:center">
                        <el-empty description="数据为空~"></el-empty>
                    </div>
                   
                    <div>
                        <div v-for="(item,index) in str_list" :key="index" style="text-align:left;font-size:14px">
                             <i class="el-icon-message-solid"></i> <span>{{item.current_publish_date}}</span>
                             <el-divider></el-divider>

                        </div>
                            
                    </div>
                    <div  class="qikanList" style="display:flex;justify-content:center;align-items:center">
                        <el-button-group>
                            <el-button @click="prevPage_str" :disabled="str_page<=1" type="primary" icon="el-icon-arrow-left">上一页</el-button>
                            <el-button @click="nextPage_str" :disabled="!str_isMore" type="primary">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                        </el-button-group>
                    </div>
                   
                </el-tab-pane>
            </el-tabs>
        </div>
        <div style="height:100px"> </div>
    </div>
</template>


<script>
// var search_key_obj = require('./search_keyword.json')
import listItem from "../components/listItem.vue";
export default {
    name: 'Home',
    components: {
        "list-item":listItem
    },
    data() {
        return {
            activeName:"first",
            search_key: null,
            form: {
                "keywords": "",
                "categories": [],
                "network": [],
                "level": [],
                "period": [],
                "checkedIncluded": [],
                "pageses": [],
                "examination_standard": [],
                "invoice_type": [],
                "pause": [],
                "batch_id": [],
                "postcode": [],
                "electronic_num": [],
                "check_draft_phone": [],
                "cover_core": [],
                "factor": [],
                "influence_id": [],
                "published_date": "",
                "published": "",
                "agentPriceOrder": ""
            },
            per_page:1,//期刊列表页面数字。
            per_list:[],//期刊列表数据。
            loadding:true,
            per_isMore:true,
            per_loading:false,
            // 列表文字
            str_page:1,
            str_list:[],
            str_isMore:true,
            str_loading:false,
            idtme:88899888,
            loadMoreSearch:false,
        }
    },
    mounted(){
        this.getsearchkey();
       
    },
    watch:{
        form:{
            deep:true,
            handler(){
               let t = this;
               clearTimeout(this.idtme);
               this.idtme = setTimeout(() => {
                    t.search();
                }, 500);
                
            }
        }
    },
    methods: {
        timechange(e){
           if(e==null ||!e){
               this.$set(this.form,'published',"")
           }
        },
        handleClick(e){
            let index = parseInt(e.index)
            if(index==0){
                if(this.per_list.length==0){
                    this.getPeriodicalList();
                }
            }else if(index==1){
               
                if(this.str_list.length==0){
                    this.getperStrList();
                }
            }
        },
        search(){
            this.per_list = [];
            this.loadding = false;
            this.per_isMore = true;
            this.per_page=1;
            this.getPeriodicalList();
        },
        reset(){
            this.$refs.form.resetFields();
            this.$set(this.form,'keywords',"")
            this.search();
        },
        //获取搜索条件
        getsearchkey(){
            this.$api.getsearchkey().then(d=>{
                this.search_key = d;
                this.per_list = [];
                this.getPeriodicalList();
            })
        },
        getPeriodicalList(){
            if(!this.$store.state.token){
                this.$message({ message: '你还未登录', type: 'error' })
                this.$store.commit("setloginAlert", true);
                return;
            }
            if(!this.search_key) return;
            let t = this;
            t.per_loading=true;
            let form = Object.keys(this.form);
            let formData={...this.form};
            form.forEach((el)=>{
                if(el!='published_date'&& el!='keywords'){
                    let p = formData[el];
                    let pd = null;
                    if(el=="published"||el=="agentPriceOrder"){
                        let ix = t.search_key[el].findIndex(al=>al.text==p);
                        if(ix>-1){
                           pd = t.search_key[el][ix].id;
                        }
                    }else{
                        pd = p.map((im,index)=>{
                            let ix = t.search_key[el].findIndex(al=>al.text==im);
                            if(ix>-1){
                                return t.search_key[el][ix].id;
                            }
                            return im;
                        })
                    }
                   
                    formData[el] = pd;
                }
            })
           
            if(formData.published_date){
                let d = new Date(formData.published_date);
                let dy = d.getMonth()+1;
                dy = dy<10?'0'+dy:dy;
                formData.published_date = d.getFullYear() +'-'+dy
            }
            formData.published = formData.published!=null?[formData.published]:[];
            formData.agentPriceOrder = formData.agentPriceOrder?[formData.agentPriceOrder]:[]
            this.$api.getPeriodicalList(this.per_page,formData).then(d=>{
                if(d.data.length>0){
                   this.per_list = [...this.per_list,...d.data];
                   this.per_page+=1;
                }else{
                    t.per_isMore = false;
                }
               
               setTimeout(() => {
                  t.per_loading=false; 
               }, 500);
            })
        },
        nextPage_str(){
            this.str_page+=1;
            this.getperStrList();
        },
        prevPage_str(){
            let page = this.str_page-1;
           
            page = page <=1 ?1 :page;
            this.str_page = page;
            this.getperStrList();
        },
        getperStrList(){
            let t = this;
            t.str_loading=true;
            this.$api.getperStrList(this.str_page).then(d=>{
                if(d.data.length>0){
                   this.str_list = [...d.data];
                   
                }else{
                    t.str_isMore = false;
                }
               setTimeout(() => {
                  t.str_loading=false; 
               }, 500);
            })

        }
    }
}

</script>
<style lang="scss" scoped>
.loadmore{
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 14px;
    color:#01a1c4;
    cursor: pointer;
    padding-bottom: 24px;
}
.qikanList{
    
    padding: 32px 24px;
    
    border-radius: 26px;
    text-align: left;
    overflow-y: auto;
    // height: 674px;
    display: flex;
    flex-flow: row wrap;

    .bdy-right-item{
        margin-bottom: 24px;
        width: calc(50% - 24px);
        &:nth-child(2n+1){
            margin-right: 48px;
        }
    }
}
.searchrulst{
    padding: 0 50px;
    max-width: 1250px;
    margin: auto;
     box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
}
.el-form-item--small.el-form-item {
    margin-bottom: 0px;
}
.searchForm {
    padding: 0 50px;
    max-width: 1250px;
    margin: auto;
    overflow: hidden;
    // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin-top: 32px;
    .searchFormBody{
        transition: all 0.5s;
    }
}
.homebg {
    background-image: url(../assets/headerBg.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 178px;
    .homebgBody {
        padding: 0 50px;
        max-width: 1350px;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
        .homebgBody-title {
            font-size: 31px;
            color: white;
            padding-bottom: 12px;
        }
        .homebgBody-input {
            display: flex;
            width: 100%;
        }
    }
}
</style>
