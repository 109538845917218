<!--
 * @Date: 2021-12-20 13:59:20
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\components\HeaderVue.vue
-->
<template>
	<div class="header">
		<div class="header-body">
			<div class="header-logo">
				<!-- <router-link to="/"><img alt="Vue logo" width="136" height="31" src="../assets/logo.png" /></router-link> -->
				<router-link to="/" style="font-size: 21px;color: #01a1c4;">期刊查询系统</router-link>
			</div>
			<div>
				<router-link to="/tongji" style="font-size:15px">出刊统计</router-link>
				<span style="padding:0 10px">|</span>
				<el-button @click="showLoginAlert" v-if="!$store.state.token" size="small" type="primary">请登录</el-button>
				<el-popover v-if="$store.state.token" placement="top-start" width="200" trigger="hover">
					<template>
						<div v-if="$store.state.user" style="background:#01A1C4;color:white;border-radius:3px;padding:5px;margin-bottom:12px">
							<div style="font-size:16px">{{ $store.state.user.name }}</div>
							<div style="font-size:13px">{{ $store.state.user.phone }}</div>
						</div>
						<div @click="showChangPassword = true" class="itemlist">
							<div>
								<i class="el-icon-lock"></i>
								<span style="padding-left:5px">修改密码</span>
							</div>
							<i class="el-icon-arrow-right"></i>
						</div>

						<router-link to="/tongji" class="itemlist">
							<div>
								<i class="el-icon-s-data"></i>
								<span style="padding-left:5px">出刊统计</span>
							</div>
							<i class="el-icon-arrow-right"></i>
						</router-link>
						<router-link to="/feed" class="itemlist">
							<div>
								<i class="el-icon-s-comment"></i>
								<span style="padding-left:5px">更新与反馈</span>
							</div>
							<i class="el-icon-arrow-right"></i>
						</router-link>
						<div @click="loginOut" class="itemlist">
							<div>
								<i class="el-icon-s-promotion"></i>
								<span style="padding-left:5px">退出系统</span>
							</div>
							<i class="el-icon-arrow-right"></i>
						</div>
					</template>
					<template #reference>
						<span class="wodeCenter">
							<span style="font-size:15px">{{ $store.state.user ? $store.state.user.name : '' }}</span>
							<i class="el-icon-caret-bottom"></i>
						</span>
					</template>
				</el-popover>
			</div>
		</div>
		<!-- 登录弹层 -->
		<el-dialog :visible.sync="$store.state.showLogin" width="800px">
			<div class="login">
				<div style="padding-right:80px">
					<div class="login-title">欢迎登录期刊查询系统</div>
					<el-tabs v-model="activeName">
						<el-tab-pane label="微信登录" name="weixin">
							<div style="padding-top:12px;width:300px">
								<!-- <div style="font-size:21px;padding-bottom:21px">微信登录</div> -->
								<div>
									<!-- <img
                                        width="150"
                                        height="150"
                                        src="https://open.weixin.qq.com/connect/qrcode/0319Rn422HT30w3Q"
                                    /> -->
									<wxmp></wxmp>
								</div>
								<!-- <div style="font-size:12px;padding-top:12px">
                                    请使用微信扫描二维码登录
                                    <br />“期刊查询系统”
                                </div> -->
							</div>
						</el-tab-pane>
						<el-tab-pane label="使用帐户登录" name="account">
							<div style="padding-top:24px;width:300px">
								<el-alert v-if="isWeixAuthSuccess" title="微信授权成功，需要你使用帐户登录进行绑定，之后就可以自动登录啦。" type="success"></el-alert>
								<div style="height: 24px;"></div>
								<el-form ref="loginActions" :model="form" label-position="left" label-width="90px" :rules="rules">
									<el-form-item label="手机号码" prop="user"><el-input placeholder="请输入手机号码" v-model="form.user" clearable></el-input></el-form-item>
									<el-form-item label="帐户密码" prop="password">
										<el-input placeholder="请输入您的帐户密码" v-model="form.password" clearable type="password"></el-input>
									</el-form-item>
									<el-form-item label="验证码 " prop="code">
										<div style="display:flex">
											<el-input placeholder="输入验证码" v-model="form.code" clearable></el-input>
											<el-image style="cursor: pointer" @click="getCodeImg" :src="codeimg"></el-image>
											<!-- <el-button
                                                @click="playGetcode"
                                                plain
                                                type="primary"
                                                style="margin-left:12px;width:90px"
                                            >获取</el-button> -->
											<!-- <el-button
                                                @click="playGetcode"
                                                plain
                                                type="primary"
                                                style="margin-left:12px;width:90px"
                                            >{{kaishiPlayCode?jishiNum+'s':'获取'}}</el-button> -->
										</div>
									</el-form-item>
									<el-form-item>
										<el-button :type="isWeixAuthSuccess ? 'success' : 'primary'" @click="loginActions" style="width:125px">
											{{ isWeixAuthSuccess ? '绑定微信' : '登录' }}
										</el-button>
										<el-button @click="reset">重置</el-button>
									</el-form-item>
								</el-form>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div><img width="300" src="../assets/logoRight.png" /></div>
			</div>
		</el-dialog>
		<!-- 修改密码弹层 -->
		<el-dialog :visible.sync="showChangPassword" width="600px">
			<div class="login">
				<div>
					<div class="login-title">修改帐户密码</div>
					<el-form ref="loginForm" :model="formChangPassword" label-position="left" label-width="90px" :rules="rules">
						<el-form-item label="手机号码" prop="user">
							<el-input placeholder="请输入你登录的手机号码" v-model="formChangPassword.user" clearable></el-input>
						</el-form-item>
						<el-form-item label="帐户密码" prop="password">
							<el-input placeholder="请输入您的旧帐户密码" v-model="formChangPassword.password" clearable type="password"></el-input>
						</el-form-item>
						<el-form-item label="新的密码" prop="newpassword">
							<el-input placeholder="请输入您的新密码" v-model="formChangPassword.newpassword" clearable type="password"></el-input>
						</el-form-item>
						<el-form-item v-if="false" label="验证码 " prop="code">
							<div style="display:flex">
								<el-input placeholder="输入验证码" v-model="formChangPassword.code" clearable></el-input>
								<el-button @click="playGetcode" plain type="primary" style="margin-left:12px;width:90px">{{ kaishiPlayCode ? jishiNum + 's' : '获取' }}</el-button>
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit" style="width:237px">确认修改密码</el-button>
							<el-button @click="reset">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import wxmp from "../components/wxmp.vue";

export default {
    name: 'HeaderVue',
	components: {
	    "wxmp":wxmp
	},
    data() {
        let passwordVal = (rule, value, callback) => {
            let p = /^[\w+|\-|+\*\.\`!@#\$%\^\&\(\)\_\+\,\///]{6,}$/
            if (!p.test(value)) return callback(new Error('密码至少需要6位，只能是数字和字母'));
            callback();
        };
        return {
            showLogin: false,
            showChangPassword: false,
            activeName: 'account',
            form: {
                user: '',
                password: '',
                code: '',
                key:''
            },
            rules: {
                user: [{
                    required: true,
                    validator: (rule, value, callback) => {
                        if (value === '') return callback(new Error('不能为空，请输入手机号码'));
                        let rul = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[03678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(value);
                        if (!rul) callback(new Error('手机号码填写不正确'));
                        callback();
                    },
                    trigger: 'blur'
                }],
                password: [{ required: true, validator: passwordVal, trigger: 'change' }],
                newpassword: [{ required: true, validator: passwordVal, trigger: 'change' }],
                code: [{ required: true, min: 4, max: 4, message: '不能为空,且为4位', trigger: 'blur' }]
            },
            daojishiTimeId: 888,
            kaishiPlayCode: false,//获取验证码是否倒计时。
            jishiNum: 60,
            formChangPassword: {
                user: '',
                password: '',
                newpassword: '',
                // code: ''
            },
            is_loading: false,
            codeimg:'',
			isWeixAuthSuccess:false,
			logintype:0,
        }
    },
    destroyed() {
        clearInterval(this.daojishiTimeId);
    },
    async mounted() {

        if(this.$route.query?.wxlogin==1){
			window.top.callParentPageMethod(location.origin+"/?wxlogin=2")
			return;
		}
		if(this.$route.query?.wxlogin==2){
			this.logintype=2;
			//微信授权登录。自动登录。
			await this.wxauloging()
		}
        this.$store.dispatch("getLoginUserinfo");
        //获取第一次key
        if(!this.codeimg){
            this.getCodeImg();
        }
    },
    methods: {
		async wxauloging(){
			let t = this;
			 t.is_loading = true;
			if(this.$store.state.token) return;
			let d = await this.$api.getWebToken().catch(e => {
			    t.is_loading = false;
				t.logintype = 0;
				t.$message({ message: '登录失败，请刷新页面重试', type: 'error' })
				
			})
			if(!d){
				t.isWeixAuthSuccess=true;
				t.$message({ message: '微信授权成功，需要你使用帐户登录进行绑定，之后就可以自动登录啦。', type: 'success' })
				
				return;
			}
			t.is_loading = false;
			this.$store.commit("setloginAlert", false);
			t.$store.commit("login", d);
			t.showChangPassword = false;
			history.pushState(null,null,'/')
			this.$alert('授权成功，请稍等服务返回登录信息！', '授权成功', {type:"success"})
			setTimeout(function(){
				location.reload()
			},1000)
		},
        onSubmit() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.ChangPassword();
                } else {
                    this.$message({ message: '请全部填写正确', type: 'error' })
                    return false;
                }
            });
        },
        showLoginAlert(){
            this.$store.commit("setloginAlert", true);
        },
        loginActions() {
            this.$refs.loginActions.validate((valid) => {
                if (valid) {
                    this.loginuser();
                } else {
                    this.$message({ message: '请全部填写正确', type: 'error' })
                    return false;
                }
            });
        },
        reset() {
            this.$refs.loginForm.resetFields();
        },
        ChangPassword() {
            let t = this;
            t.is_loading = true;
            this.$api.changepassword(this.formChangPassword.user, this.formChangPassword.password, this.formChangPassword.newpassword).then(d => {
                setTimeout(async () => {

                    await this.$alert("修改密码成功，需要重新登录，请点确认", "提醒")
                    t.is_loading = false;
                    t.showChangPassword = false;
                    t.loginOut();

                }, 500);
            }).catch(e => {
                t.is_loading = false;
            })
        },
        loginuser() {
            let t = this;
            t.is_loading = true;
            this.$api.loginaccont(this.form.user, this.form.password,this.form.key, this.form.code).then(d => {
                setTimeout(() => {
                    t.is_loading = false;
                    t.$store.commit("login", d);
                    t.showChangPassword = false;
					history.pushState(null,null,'/')
                    location.reload(location.host);
                }, 500);
            }).catch(e => {
                t.is_loading = false;
            })
        },
        playGetcode() {
            if (this.kaishiPlayCode == true) return;
            let rul = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(this.form.user)
            if (!this.form.user || !rul) {
                this.$message({ message: '手机号码未填写或者不正确', type: 'error' })
                return;
            }
            let t = this;
            t.kaishiPlayCode = true;
            t.daojishiTimeId = setInterval(() => {
                t.jishiNum -= 1;
                if (t.jishiNum <= 0) {
                    clearInterval(t.daojishiTimeId);
                    t.jishiNum = 60;
                    t.kaishiPlayCode = false;
                }
            }, 1000);
        },
        loginOut() {
            let t = this;
            this.$api.logout().then(d => {
                t.$store.commit("loginOut");
                setTimeout(() => {
                    location.reload();
                }, 500);
            })

        },
        getCodeImg() {
            let t = this;
            this.$api.getCodeImg(this.form.key).then(d => {
                t.$set(t.form,"key",d.key)
                t.codeimg = d.img
            })

        }
    }
}
</script>

<style scoped lang="scss">
.login {
	display: flex;
	width: 100%;
	justify-content: center;

	.login-title {
		font-size: 21px;
		color: #01a1c4;
		margin-bottom: 24px;
	}
}
.wodeCenter {
	&:hover {
		&,
		i {
			cursor: pointer;
			color: #01a1c4;
		}
	}
}
.itemlist {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 10px;
	font-size: 14px;
	color: #333;
	&:hover {
		color: #01a1c4;
		cursor: pointer;
	}
}
.header {
	height: 65px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	width: 100%;
	background: white;

	.header-body {
		max-width: 1250px;
		margin: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		padding: 0 50px;
	}
	.header-logo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}
</style>
