/*
 * @Date: 2021-12-20 11:42:45
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HeaderVue from '../components/HeaderVue.vue'
import Footer from '../components/Footer.vue'

Vue.use(VueRouter)
function backCom(custom){
  return {
    header: HeaderVue,
    footer: Footer,
    ...custom
  }
}
function metakeyw(title){
  return {
    title: "期刊查询系统" + (title || ""),
    description: "期刊查询系统",
    keyword: "期刊查询系统"
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      ...backCom({ body:Home})
    },
    meta: metakeyw()
  },
  {
    path: '/tongji',
    name: 'Tongji',
    components: {
      ...backCom({ body: () => import('../views/tongji.vue') })
    },
    meta: metakeyw("半年统计")
  },
  {
    path: '/feed',
    name: 'feedback',
    components: {
      ...backCom({ body: () => import('../views/feedback.vue') })
    },
    meta: metakeyw("系统更新与反馈")
  }
]

const router = new VueRouter({
	mode: 'history',
    routes
})

export default router
