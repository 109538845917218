<!--
 * @Date: 2021-12-20 13:59:20
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\components\footer.vue
-->
<template>
  <div class="footer">Copyright 2021-201  版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">网站备案信息：赣ICP备2022001476号</a> </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  color: #888;
}
.footer a{
  color: #888;
  padding-left: 10px;
}
</style>
