<!--
 * @Date: 2021-12-23 13:57:09
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\components\wxmp.vue
-->
<template>
	<div class="mpalert">
		<el-popover placement="top-start" title="微信扫码" width="200" trigger="hover" >
			<div >
				<img src="../assets/xcxqr.jpg" width="200" height="200" style="padding-right:6px" />
			</div>
			<div slot="reference" class="mpalert-item">
				<img src="../assets/wxmp.png" width="36" height="36" style="padding-right:14px" />
				<div>小程序版本</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
export default {
	name: 'mpalert',
	data() {
		return {};
	},
	created() {},
	mounted() {}
};
</script>

<style scoped lang="scss">
.mpalert {
	position: fixed;
	right: 0;
	bottom: 150px;
	width: 170px;
	z-index: 5;
}
.mpalert-item {
	background-color: #01a1c4;
	border-radius: 100px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 16px;
	transform: translateX(50%);
	transition: all 0.5s;
	&:hover{
		transform: translateX(0%);
	}
}
</style>
