/*
 * @Date: 2021-12-20 11:42:31
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var token = localStorage.getItem('token')||"";
import api from '../api'
export default new Vuex.Store({
  state: {
    token: token,
    showLogin:false,
    user:null,
  },
  mutations: {
    loginOut(state){
      localStorage.removeItem("token");
      state.token = "";
    },
    login(state,token) {
      if (token&&typeof token =='string'){
        localStorage.setItem("token", token);
        state.token = token;
       
      }
    },
    setloginAlert(state, val) {
      state.showLogin = val||false;

    },
    setUser(state,info){
      if(typeof info =='object' && info){
        state.user = { ...info};
      }
    }
  },
  actions: {
    getLoginUserinfo({ commit}){
      if(!token) return;
      api.getuserinfo().then(d => {
        if (d){
          commit("setUser", d)
        }
      })
    }
  },
  modules: {
  }
})
