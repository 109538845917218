<!--
 * @Date: 2021-12-23 13:57:09
 * @LastEditors: liuping
 * @Author: liuping
 * @Description: 文件
 * @FilePath: \nodeJS91xtwd:\公司客户\伴优教育\pcver\src\components\wxmp.vue
-->
<template>
	<div class="wxmp">
		<div class="wxmp-item" v-if="!$store.state.token">
			<!-- <img src="../assets/wxmp.png" width="24" height="24" style="padding-right:6px" /> -->
			<!-- <iframe src="/wechat.html" width="360" height="400" noresize style="border: none;overflow: hidden;"></iframe> -->
			<div id="wxlogin" width="360" height="400" style="border: none;overflow: hidden;">

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'wxmp',
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
			var obj = new WxLogin({
				self_redirect: false,
				id: "wxlogin",
				appid: "wx285c0467faf96376",
				scope: "snsapi_login",
				redirect_uri: "https://www.jxbyjy.cn/wx/webLogin",
				state: "",
				style: "",
				href: ""
			});
		},
	}
</script>

<style scoped lang="scss">

</style>
